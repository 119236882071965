import {
    GET_USERS,
    CREATE_USER,
    CREATE_FIREBASE_USER,
    UPDATE_USER,
    DELETE_USER,
    CREATE_AUTHENTICATION,
    DELETE_AUTHENTICATION,
    CREATE_AUTHORIZATION,
    DELETE_AUTHORIZATION
} from "./types"
import axios from "../auth/axiosAuth"
import { backendURL } from "../config"

export const getUsers = () => async (dispatch) => {
    const users = await axios.get(`${backendURL}/user`)
    const employees = await axios.get(`${backendURL}/employee`)

    const usersWithEmployees = users.data.map((user) => {
        const employee = employees.data.filter(
            (employee) => employee.AssociateOID === user.AssociateOID
        )[0]

        const employeeName = `${employee?.FirstName} ${employee?.LastName}`
        return { ...user, User: employeeName }
    })

    const usersWithAuthentications = await Promise.all(
        usersWithEmployees.map(async (user) => {
            const authentications = await axios.get(
                `${backendURL}/authentication/user/${user.AssociateOID}`
            )
            return { ...user, Authentications: authentications.data }
        })
    )

    const usersWithAuthorizations = await Promise.all(
        usersWithAuthentications.map(async (user) => {
            const authenticationsWithAuthorizations = await Promise.all(
                user.Authentications.map(async (authentication) => {
                    const authorizations = await axios.get(
                        `${backendURL}/authorization/user/application/${user.AssociateOID}/${authentication.ApplicationID}`
                    )
                    return {
                        ...authentication,
                        Authorizations: authorizations.data
                    }
                })
            )
            return {
                ...user,
                Authentications: authenticationsWithAuthorizations
            }
        })
    )

    const usersWithAuthorizationsSorted = usersWithAuthorizations.sort((a, b) =>
        a.User > b.User ? 1 : b.User > a.User ? -1 : 0
    )

    dispatch({ type: GET_USERS, payload: usersWithAuthorizationsSorted })
}

export const createUser = (newUser) => async (dispatch) => {
    await axios.post(`${backendURL}/user`, newUser)
    dispatch({ type: CREATE_USER })
}

export const createFirebaseUser = (newUser) => async (dispatch) => {
    const res = await axios.get(
        `${backendURL}/employee/aoid/${newUser.AssociateOID}`
    )
    if (res.data[0] && res.data[0]?.BusinessEmail.length > 5) {
        const employee = res.data[0]
        const newFirebaseUser = {
            AssociateOID: newUser.AssociateOID,
            AzureID: newUser.AzureID,
            UserName: employee.BusinessEmail,
            DisplayName: `${employee?.FirstName} ${employee?.LastName}`
        }
        await axios.post(`${backendURL}/user/firebase`, newFirebaseUser)
    }
    dispatch({ type: CREATE_FIREBASE_USER })
}

export const updateUser = (updatedUser) => async (dispatch) => {
    await axios.put(`${backendURL}/user`, updatedUser)
    dispatch({ type: UPDATE_USER })
}

export const deleteUser = (associateOID, googleID) => async (dispatch) => {
    await axios.delete(`${backendURL}/user/${associateOID}/${googleID}`)
    dispatch({ type: DELETE_USER })
}

export const createAuthentication = (newAuthentication) => async (dispatch) => {
    await axios.post(`${backendURL}/authentication`, newAuthentication)
    dispatch({ type: CREATE_AUTHENTICATION })
}

export const deleteAuthentication =
    (selectedAuthentication, associateOID) => async (dispatch) => {
        await axios.delete(
            `${backendURL}/authorization/user/application/${associateOID}/${selectedAuthentication.applicationID}`
        )
        await axios.delete(
            `${backendURL}/authentication/${selectedAuthentication.authenticationID}`
        )
        dispatch({ type: DELETE_AUTHENTICATION })
    }

export const createAuthorization = (newAuthorization) => async (dispatch) => {
    await axios.post(`${backendURL}/authorization`, newAuthorization)
    dispatch({ type: CREATE_AUTHORIZATION })
}

export const deleteAuthorization = (authorizationID) => async (dispatch) => {
    await axios.delete(`${backendURL}/authorization/${authorizationID}`)
    dispatch({ type: DELETE_AUTHORIZATION })
}
